<template>
  <div class="sub-title">
    <p>{{ title }}</p>
    <a-tooltip v-if="tooltips" placement="left" :title="tooltips" :get-popup-container="getPopupContainer">
      <a-icon type="question-circle"/>
    </a-tooltip>
  </div>
</template>

<script>
export default ({
  props: {
    title: {
      type: String,
      default: ''
    },
    tooltips: {
      type: String,
      default: ''
    }
  },
  methods: {
    getPopupContainer(trigger) {
      return trigger.parentElement;
    },
  },
})

</script>


<style scoped lang="scss">
.sub-title {
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    margin: 0;
  }
}
</style>